















































































































































import { User } from '@/models/auth/interfaces';
import { useAuth } from '@/modules/auth';
import { defineComponent, reactive } from '@vue/composition-api';
import {
  LOGOUT_PATH,
  LOGIN_PATH,
  PROJECT_START_PATH,
  HOME_PATH,
  VERSIONS_PATH,
} from '@/constants/routes';
import UpgradeFromTrial from '@/layout/components/upgrade-license/index.vue';
import UserPwModal from '@/components/user-adm/user-pwreset-modal/index.vue';
import Language from '@/layout/components/language/index.vue';
import { useGlobal } from '@/modules/global';

interface State {
  loggedIn: boolean;
  user: User | undefined;
}

export default defineComponent({
  components: {
    UpgradeFromTrial,
    UserPwModal,
    Language,
  },
  setup() {
    const { user, license } = useAuth();
    const { appVersion, requestFeedback } = useGlobal();

    const state = reactive<State>({
      loggedIn: user?.value !== undefined,
      user: user?.value,
    });

    return {
      appVersion,
      requestFeedback,
      state,
      LOGOUT_PATH,
      LOGIN_PATH,
      PROJECT_START_PATH,
      HOME_PATH,
      VERSIONS_PATH,
      user,
      license,
      registerUrl: process.env.VUE_APP_REGISTER_URL,
      downloadExeURL: process.env.VUE_APP_EXE_DOWNLOAD_URL,
      supportUrl: process.env.VUE_APP_SUPPORT_URL,
      documentationUrl: process.env.VUE_APP_DOCUMENTATION_URL,
    };
  },
});
